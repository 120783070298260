:root {
  --primary-color: #535353; /* Medium blue */
  --secondary-color: 	#121212; /* Light blue-gray */
  --accent-color: #1db954; /* Very light grayish-white */
  --background-color: #212121; /* Very dark blue */
  --text-color: #b3b3b3; /* Very light grayish-white */

  font-family: 'Helvetica', 'Arial', sans-serif;
    color: #dadada;
    font-weight: bold;
}

.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 1vmin);
  
  background: linear-gradient(109deg, #121212, #212121, #181818);
    background-size: 800% 800%;
  -webkit-animation: bg-gradient 45s ease infinite;
  -moz-animation: bg-gradient 45s ease infinite;
  animation: bg-gradient 45s ease infinite;

}
.App-Content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@-webkit-keyframes bg-gradient {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes bg-gradient {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes bg-gradient {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

.icon-container{
  width: 40px;
  height: 40px;

  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-color: #1db954;
  background-color: #121212;
  border-radius: 100%;
  cursor:pointer;
  box-shadow: 0 1.5rem 3rem -0.75rem #000000;

}


.file-input{
  display: none;
}

.App-logo{
  width: 300px;
}

.image-container{
  height: 400px;
  width: 300px;
  display: flex;
  overflow-x: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  box-shadow: 0 1.5rem 3rem -0.75rem #000000;
  border-radius: 10px;
  background-color: #121212;
  scrollbar-width: none;
}

.slider-img{
  margin-left: 4px;
  height: 400px;
  width: 300px;
  flex: 1 0 100%;
  scroll-snap-align: start;
  object-fit: cover;
  filter:blur(4px)

}

.spotify{
  margin-top: 15px;
  height: 100px;
  width: 300px;
}

.imgslider{
  margin-bottom: -20px;
  border-radius: 20px;
  border-color: #414141;
  border-style: solid;
  border-width: 1px;
  padding-top: 15px;
  height: 500px;
  width: 330px;
  justify-content: center;
  align-items:center;
  display: flex;
  flex-direction: column;
}
.try-text{
  color: #414141;
  font-weight: normal;
  margin-top: 0px;
}